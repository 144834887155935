const isBrowser = typeof window !== "undefined" && window;

// Check all redirect paths in lowercase
const REDIRECT_MAP = {
  "/zoom/effie": "https://us02web.zoom.us/j/9210033294",
  "/zoom/zack":
    "https://us02web.zoom.us/j/3557138340?pwd=Z1puWXpvM0tudU5nMXlscEU3WWNuZz09",
};
// Add trailing slashes
Object.keys(REDIRECT_MAP).forEach((key) => {
  REDIRECT_MAP[key + "/"] = REDIRECT_MAP[key];
});

const ZoomPage = ({ location }) => {
  if (!isBrowser) {
    return null;
  }
  let maybeRedirectPath = REDIRECT_MAP[location.pathname.toLowerCase()];
  window.location = maybeRedirectPath || "/";
  return null;
};

export default ZoomPage;
